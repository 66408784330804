import { logError } from "common/errors";
import { getFirebaseAuth, getMicrosoftProvider } from "common/Firebase";
import {
  Auth as FirebaseAuth,
  signInWithRedirect,
  User,
  UserCredential,
} from "firebase/auth";
import * as ciap from "gcip-iap";
import { Dispatch, SetStateAction } from "react";

export const signInHandler = (
  errorHandler: (error: Error | ciap.CIAPError) => void,
) => {
  signInWithRedirect(getFirebaseAuth(), getMicrosoftProvider()).catch(
    (error) => {
      logError(error as Error);
      errorHandler(error);
    },
  );

  return false;
};

export class AppAuthenticationHandler implements ciap.AuthenticationHandler {
  languageCode?: string | null | undefined;
  errorHandler: (error: Error | ciap.CIAPError) => void;
  setIsLoading: Dispatch<SetStateAction<boolean>>;

  constructor(
    errorHandler: (error: Error | ciap.CIAPError) => void,
    setIsLoading: Dispatch<SetStateAction<boolean>>,
  ) {
    this.errorHandler = errorHandler;
    this.setIsLoading = setIsLoading;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getAuth(_: string, __: string | null) {
    return getFirebaseAuth();
  }

  async startSignIn(
    _auth: FirebaseAuth, // eslint-disable-line @typescript-eslint/no-unused-vars
    _?: ciap.SelectedTenantInfo | undefined, // eslint-disable-line @typescript-eslint/no-unused-vars
  ): Promise<UserCredential> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((_resolve, _reject) => {});
  }

  completeSignOut(): Promise<void> {
    return Promise.resolve();
  }

  handleError?(error: Error | ciap.CIAPError): void {
    this.errorHandler(error);
  }

  showProgressBar?(): void {
    this.setIsLoading(true);
  }

  hideProgressBar?(): void {
    this.setIsLoading(false);
  }

  processUser?(user: User): Promise<User> {
    return Promise.resolve(user);
  }
}
