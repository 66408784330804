import { datadogLogs, LogsInitConfiguration } from "@datadog/browser-logs";
import { datadogRum, RumInitConfiguration } from "@datadog/browser-rum";

import { appVersion, isDev } from "./util";

export const setupDatadog = (serviceName: string) => {
  if (isDev) {
    return;
  }

  const config = {
    applicationId: import.meta.env.VITE_DATADOG_APP_ID,
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
    site: "datadoghq.eu",
    service: serviceName,
    env: import.meta.env.VITE_ENV_NAME,
    version: appVersion,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
  };

  const rumConfig: RumInitConfiguration = {
    ...config,
    defaultPrivacyLevel: "mask-user-input",
  };

  const logsConfig: LogsInitConfiguration = {
    ...config,
    forwardErrorsToLogs: true,
  };

  datadogRum.init(rumConfig);
  datadogLogs.init(logsConfig);
};
